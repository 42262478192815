
@import "~@/assets/scss/global.scss";
@import "~@/assets/scss/variables";
@import '~@tangocard/swag/src/scss/partials/common/mango_colors';
// want to use an id as it has a powerful override to make sure these styles get applied
#app {
  font-weight: var(--t-font-weight-4);
}
// This was the only way I was able to override the styles of Vuetify for the status filter component
#app .menu-dropdown {
  box-shadow:var(--t-shadow-3);
  border: 1px solid var(--t-color-border) !important;
  border-radius: var(--t-radius-1) !important;
  margin-block-start: .25rem;
}

#app .v-menu__content {
  box-shadow:var(--t-shadow-3);
  border: 1px solid var(--t-color-border) !important;
  border-radius: var(--t-radius-1) !important;
  margin-block-start: .25rem;
}

#app .v-autocomplete__content {
  box-shadow:var(--t-shadow-3);
  border: 1px solid var(--t-color-border) !important;
  border-radius: var(--t-radius-1) !important;
  margin-block-start: .25rem;
}

#app .v-btn {
  outline: 0;
  box-shadow: none;
}

#app a {
    color: var(--t-color-text-link) !important;
}

#app .v-tab:hover {
  text-decoration: none !important;
}
#app #core-orders-col-orderTotal {
  text-align: right;
  padding-inline-end: .5rem;
}

#app {
  ::v-deep .btn-success,
  ::v-deep .btn-primary,
  .btn-info {
    color: var(--t-color-text-inverted);
}

  &.white-background {
    padding-top: 30px
  }

  &.content-pages {
    background: var(--t-color-background);
  }

  &.topbar-invisible {
    transition: padding 1s ease 0s;
    padding-top: 0;
  }

  #main-row {
    position: relative;
    margin: 0;
  }

  .visible-nav {
    transition: 300ms;
    padding-left: 62px !important;

    &.sidebar-open {
      @include media-breakpoint-up(md) {
        padding-left: $iconav-width-open;
      }
    }
  }

  .account-switcher-container {
    width: 22.5%;
  }

  #portal-content-wrap {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .vue-select {
    height: 43px;
    line-height: 43px;

    input {
      &[type=search] {
        display: none;
      }
    }

    li {
      line-height: 43px;
      letter-spacing: 1px;
    }

    .flag-icon {
      margin-right: 10px;
    }

    &.searchable {
      .dropdown-toggle {
        line-height: 43px;
        height: 43px;
      }
    }

    .dropdown-toggle {
      .selected-tag {
        margin: 0 0 0 5px;
      }
      .clear {
        bottom: 3px;
        right: 27px;
      }
      .open-indicator {
        bottom: 1px;
      }

      .open-indicator {
        bottom: 8px;
      }

      .clear {
        bottom: 10px;
      }

      .selected-tag {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 8px 40px 8px 15px;
        letter-spacing: 1px;
        pointer-events: none;
      }
    }
  }
  .responsive-container {
    max-width: $vuetify-lg;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;

    &.small-container {
      max-width: $vuetify-sm;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

#app{
  width: 100%;
  height: 100%;
  .sticky-header-content {
    padding: 1rem 2rem 0;
    height: 100%;
  }

  .has-page-header {
    .sticky-header-content {
      padding-top: 65px;
      padding-bottom: 65px;
    }
  }
}

#app,
body.modal-open {
  .tc-button {
    border: 1px solid $primary;
    background-color: transparent;
    color: $primary;
    font-size: 14px;

    &.cancel-button {
      margin: 0 20px 0 0;
    }

    &.save-button {
      background-color: var(--t-color-primary);
      color: var(--t-color-text-inverted);
    }

    &.delete-button {
      background-color: var(--t-color-status-danger);
      color: var(--t-color-text-inverted) !important;
      border: 0;
      margin: 0;
      transition: all 150ms linear;

      &:hover {
        background-color: var(--t-color-status-danger-weak);
        transition: all 400ms linear;
      }
    }

    &[disabled] {
      background: var(--t-color-surface-inverted);
    }
  }
}

@media (max-width: 600px) {
  #app {
    .has-page-header {
      .sticky-header-content {
        display: block;
      }
    }
  }
}

.v-overlay.v-overlay--active  {
  z-index: $zindex-modal !important;
}
.v-dialog__content {
  z-index: $zindex-modal !important;
}

i.icon-physical-fulfillment {
  margin-right: 16px;
  width: 16px;
  height: 24px;
  background: no-repeat url("./assets/images/icon-physical-fulfillment-black.svg") center;
}
i.icon-digital-fulfillment {
  margin-right: 16px;
  width: 16px;
  height: 24px;
  background: no-repeat url("./assets/images/icon-digital-fulfillment.svg") center;
}

#app,
#app .container-fluid,
#main-row {
  height: 100%;
  padding: 0;
}

.v-dialog {
  .v-label {
    font-size: 14px;
  }
}

div.v-application {
  font-family: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;

  #app {
    height: 100%;

    .page-sub-header {
      position: absolute;
      display: block;
      background: var(--t-color-surface);
      z-index: $zindex-page-header;
      width: 100%;
    }

    .page-tertiary-nav {
      background: var(--t-color-surface);
    }

    .v-data-table__empty-wrapper {
      width: calc(100vw - 80px);
      display: flex;
      height: 100%;

      td {
        padding: 14px;
      }
    }
  }
}
@media (max-width: $breakpoint-lg - 1) {
  .v-application {
    #app {
      .account-switcher-container {
        width: calc(100% - 72px);
      }
    }
  }
}
@media (max-width: $breakpoint-md - 1) {
 .v-application {
   #app {
     .page-sub-header {
       margin-left: 0 !important;
       padding-left: 0 !important;
       width: 100%;
     }
     .account-switcher-container {
       width: 100%;
     }
   }
 }
}
#container-nav-tooltip {
  @include nav-tooltip("groups", 110px);
  @include nav-tooltip("email", 155px);
  @include nav-tooltip("funding", 104px);
  @include nav-tooltip("funding-history", 155px);
  @include nav-tooltip("user-settings", 137px);
  @include nav-tooltip("user-mgmt", 171px);
  @include nav-tooltip("place-order", 127px);
  @include nav-tooltip("qualtrics", 110px);
  @include nav-tooltip("beamer", 159px);
  @include nav-tooltip("order-history", 140px);
}

@media (max-width: $breakpoint-lg - 1) {
  #portal-content-wrap {
    padding: 0;
  }
}

#reward-search-bar .v-input {
  border: 1px solid var(--t-color-border);
  border-radius: 4px;
  font-weight: var(--t-font-weight-5);
  font-family: $gotham-font-family;
}

#reward-search-bar .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

#reward-search div[role=combobox]{
  font-weight: var(--t-font-weight-5);
}
