:where(button):where(:not(:active)):focus-visible {
  outline-offset: 4px;
  outline: .175rem solid var(--t-color-primary) !important;
  background-color: var(--t-color-surface);
}
#app button {
  cursor: pointer;
  border-radius: var(--t-radius-1);
  &:disabled {
    cursor: not-allowed;
  }
}
#app button.btn-disabled {
  background-color: transparent;
  border: 1px solid var(--t-color-border);
  color: var(--t-color-text);
}
#app button.btn-secondary {
  color: var(--t-color-text);
  background-color: var(--t-color-surface);
  border: 1px solid var(--t-color-border) !important;
  padding-inline: 1rem;
}
#app button.btn-danger {
  color: var(--t-color-text-inverted);
  background-color: var(--t-color-status-danger);
  border: 1px solid var(--t-color-text-inverted) !important;
  padding-inline: 1rem;
}
#app button.btn-primary {
  color:var(--t-color-text-inverted);
}

#app button.btn-primary:enabled {
  background: linear-gradient(to bottom, var(--t-color-primary), #2A2468);
  border: 1px solid #2A2468;
}

#app button.btn-primary:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

#app button.btn-primary:focus:before {
  opacity: 0;
}

#app button.btn-text {
  color: var(--t-color-primary);
  background-color: transparent;
  padding-inline: .5rem;
  text-transform: none;
}
#app button.btn-text:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
#app button.btn-icon {
  color: var(--t-color-text);
  background-color: var(--t-color-surface);
  border: 1px solid var(--t-color-border) !important;
  min-width: 2rem !important;
  padding-inline: .5rem;
}

#app .t-icon-button {
  color: var(--t-color-icon) !important;
}

#app .t-icon-button:hover {
  text-decoration: none;
}

// Reggie to audit
.btn-xs {
  height: $input-height-xs;
  @include button-size($input-padding-y-xs, $input-padding-x-xs, $font-size-base, $input-line-height-xs, $input-border-radius-xs);
}
// Reggie to audit
.input-group-append > .btn {
  border-radius: $border-radius;
}

// Reggie to audit
@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    background: inherit!important;
    color: $value!important;
    &:disabled {
      opacity: .35;
    }
  }
}

/* login button styles placed here because it's used in the login and mfa components */
.btn-login {
  background-color: $success;
  border: none;
  color: $white;
  height: 48px;
  margin-top: 10px;
  width: 100%;

  &:focus {
    outline: none !important;
  }

  &:hover {
    background-color: $success-light;
  }
}
// Reggie to audit
.btn-link {
  color: $info-dark;
  &.primary {
    color: $primary;
  }
  &:hover {
    text-decoration: underline;
  }
}

.toggle-group {
  display: flex;
  gap: 0.5rem;
}

.toggle-button {
  // TODO: Add new token for this
  background-color: white !important;
  border: var(--t-border-size-1) solid var(--t-color-border);
  color: var(--t-color-text) !important;
  font-size: var(--t-font-size-0);
  font-weight: var(--t-font-weight-5);

  &.toggle-selected {
    // TODO: Add new token for this
    background-color: #333333 !important;
    // Button width changes if no border but we want to "hide" it so set to same color as bg
    border: var(--t-border-size-1) solid #333333 !important;
    color: var(--t-color-text-inverted) !important;

    ::v-deep .v-icon {
      color: white !important;
    }
  }

  &:active {
    box-shadow: none !important;
  }
  &:disabled {
    border: 0 !important;
    pointer-events: auto;
    cursor: not-allowed !important;
  }
}
