

.funding-dialog-title {
  font-size: var(--t-font-size-1);
  font-weight: var(--t-font-weight-5);
  color: var(--t-color-text);
}

.empty-funding {
  padding: 1rem 2rem;
}

.v-tabs-bar {
  .v-tab:not(.v-tab--active),
  .v-tab--disabled {
    color: var(--t-color-text) !important; // Not loving using the !important, but it's scoped, so...
  }
}

// Vuetify default styling does a carousel with the tabs on smaller displays, we never want that
::v-deep {
  .v-slide-group__prev,
  .v-slide-group__next {
    display: none !important;
  }
  .v-slide-group__wrapper .v-tabs-bar__content {
    width: 100% !important;
  }
}

.v-tab {
  width: 50%;
  border: var(--t-border-size-1) solid var(--t-color-border);
  border-right: unset;
  border-radius: var(--t-radius-1) 0 0 var(--t-radius-1);
  text-transform: initial;

  &:last-child {
    border: var(--t-border-size-1) solid var(--t-color-border);
    border-left: unset;
    border-radius: 0 var(--t-radius-1) var(--t-radius-1) 0;
  }

  &.v-tab--active {
    color: var(--t-color-primary);
    background: var(--t-color-primary-weakest);
    border: var(--t-border-size-1) solid var(--t-color-primary-weak);
  }
}

.funding-account-tooltip {
  vertical-align: bottom;
}

.toggle-group {
  justify-content: space-between;

  .toggle-button {
    flex-grow: 1;
  }
}
